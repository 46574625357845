import React, { useState, useEffect, useMemo } from 'react';
import Metrics from './Metrics/Metrics';
import ShiftReview from './ShiftReview/ShiftReview';
import '../../styles/NewDashboard/NewDashboard.css';
import {
  fetchAssignedToFromDeviceIds,
  fetchDevicesFromUserId,
  fetchShiftsByDateRange,
  filterShiftEvents,
  fetchGeofencesForOrganization,
  fetchDashboardLeaderboard,
} from '../../api/beaverApi';
// import { fetchDeviceConfigs, pingDevices } from '../../api/beaverApi';
import { toast } from 'react-toastify';
import { useDeviceFilter } from '../../contexts/DeviceFilterContext';
import { subMinutes, format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import DashboardControls from './DashboardControls/DashboardControls';
import PersonnelStats from './PersonnelStats/PersonnelStats';
import { formatLastUpdated, formatTooltipTime } from '../../utils/dashboardUtils';
import { startOfWeek, endOfWeek, subWeeks } from 'date-fns';
import { cacheShifts, getCachedShifts, clearShiftCache } from '../../utils/shiftCache';
import { PLIX_OVERWATCH_ACCOUNT } from '../../utils/utilsEvents';
const NewDashboard = ({ userId, userRole, retentionPeriodDays }) => {
  console.log('Component Render - Props:', userId);
  const { selectedOrg } = useDeviceFilter();
  // console.log('Selected Org:', selectedOrg);

  const [devices, setDevices] = useState([]);
  const [deviceAssignments, setDeviceAssignments] = useState({});
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [error, setError] = useState(null);
  const [selectedView, setSelectedView] = useState('shift');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [currentTimePeriod, setCurrentTimePeriod] = useState({
    name: 'thisWeek',
    weeksBack: 0,
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    key: 'thisWeek',
  });

  const [isLoadingGeofences, setIsLoadingGeofences] = useState(false);
  const [geofences, setGeofences] = useState([]);
  const [rankings, setRankings] = useState({});

  // Add new state for selected personnel
  const [selectedPersonnel, setSelectedPersonnel] = useState([]);

  // Add new state for loading shifts
  const [isLoadingShifts, setIsLoadingShifts] = useState(false);

  // Add new state for flagged shifts count
  const [flaggedShiftsCount, setFlaggedShiftsCount] = useState(0);

  // Add new state for leaderboard data
  const [leaderboardData, setLeaderboardData] = useState([]);

  // Add this at the top of the component, after the initial state declarations
  const effectiveUserId = useMemo(() => {
    return userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
  }, [userId, selectedOrg]);

  // effect to fetch devices and device assignments
  // triggers on userId or selectedOrg change
  useEffect(() => {
    const fetchAndLogGeofences = async () => {
      setIsLoadingGeofences(true);
      try {
        const fetchedGeofences = await fetchGeofencesForOrganization(effectiveUserId);
        setGeofences(fetchedGeofences);
      } catch (error) {
        console.error('Error fetching geofences:', error);
      } finally {
        setIsLoadingGeofences(false);
      }
    };

    fetchAndLogGeofences();
  }, [effectiveUserId]);

  useEffect(() => {
    const fetchDevices = async () => {
      setIsLoadingDevices(true);
      try {
        if (!effectiveUserId) {
          console.log('Waiting for effectiveUserId...');
          return;
        }

        // set lastUpdated
        const now = new Date();
        const minutesSinceLastUpdate = now.getUTCMinutes() + (now.getUTCHours() % 3) * 60;
        const lastUpdateTime = subMinutes(now, minutesSinceLastUpdate % 5);
        setLastUpdated(lastUpdateTime);

        // fetch devices for the effective userId
        let fetchedDevices = await fetchDevicesFromUserId(effectiveUserId);
        console.log('Fetched devices:', fetchedDevices);
        if (!Array.isArray(fetchedDevices)) {
          throw new Error(`Invalid data format received: expected array, got ${typeof fetchedDevices}`);
        }
        const deviceIds = fetchedDevices.map((device) => device.deviceId);
        setDevices(deviceIds);
        console.log('Devices:', deviceIds);

        if (deviceIds.length > 0) {
          const assignments = await fetchAssignedToFromDeviceIds(deviceIds);
          setDeviceAssignments(assignments);
        }

        setSelectedPersonnel(deviceIds);
      } catch (err) {
        console.error('Error fetching devices:', err);
        toast.error(err.message || 'Failed to fetch devices. Please try again later.');
        setError(err.message || 'Failed to fetch devices. Please try again later.');
        setDevices([]);
        setDeviceAssignments({});
        setSelectedPersonnel([]);
      }
      setIsLoadingDevices(false);
    };

    fetchDevices();

    const intervalId = setInterval(() => {
      setLastUpdated((prevLastUpdated) => {
        if (prevLastUpdated) {
          return new Date(prevLastUpdated.getTime());
        }
        return null;
      });
    }, 60000);

    return () => clearInterval(intervalId);
  }, [effectiveUserId]);

  useEffect(() => {
    const fetchShifts = async () => {
      setIsLoadingShifts(true);
      // Clear shifts immediately when loading new period
      // setShifts([]);

      try {
        // Generate cache keys for current and previous periods
        const currentCacheKey = `${currentTimePeriod.startDate.toISOString()}_${currentTimePeriod.endDate.toISOString()}`;
        const previousStart = subWeeks(currentTimePeriod.startDate, 1);
        const previousEnd = subWeeks(currentTimePeriod.endDate, 1);
        const previousCacheKey = `${previousStart.toISOString()}_${previousEnd.toISOString()}`;

        // Always fetch fresh data for current week
        if (currentTimePeriod.name === 'thisWeek') {
          const now = new Date();
          const minutesSinceLastUpdate = now.getUTCMinutes() + (now.getUTCHours() % 3) * 60;
          const lastUpdateTime = subMinutes(now, minutesSinceLastUpdate % 5);
          setLastUpdated(lastUpdateTime);
        }

        // Check cache for current period
        const cachedData = getCachedShifts(currentCacheKey);
        if (cachedData && currentTimePeriod.name !== 'thisWeek') {
          setShifts(cachedData);
          setIsLoadingShifts(false);
        } else {
          // Fetch current period data if not cached
          const userIdForShifts = userId === PLIX_OVERWATCH_ACCOUNT ? effectiveUserId : null;
          const deviceIdForGuard = userRole === 'guard' ? devices[0] : null;
          const [shiftsResponse] = await Promise.all([
            fetchShiftsByDateRange(
              currentTimePeriod.startDate.toISOString(),
              currentTimePeriod.endDate.toISOString(),
              userIdForShifts,
              deviceIdForGuard
            ),
            isLoadingGeofences
              ? new Promise((resolve) => {
                  const checkGeofences = () => {
                    if (!isLoadingGeofences) {
                      resolve();
                    } else {
                      setTimeout(checkGeofences, 100);
                    }
                  };
                  checkGeofences();
                })
              : Promise.resolve(),
          ]);

          const filteredShiftsResponse = await Promise.all(
            shiftsResponse.map(async (shift) => await filterShiftEvents(shift))
          );

          // Cache the filtered shifts
          cacheShifts(currentCacheKey, filteredShiftsResponse);
          setShifts(filteredShiftsResponse);
        }

        // Fetch and cache previous period in background
        if (!getCachedShifts(previousCacheKey)) {
          const userIdForShifts = userId === PLIX_OVERWATCH_ACCOUNT ? effectiveUserId : null;
          const deviceIdForGuard = userRole === 'guard' ? devices[0] : null;
          fetchShiftsByDateRange(
            previousStart.toISOString(),
            previousEnd.toISOString(),
            userIdForShifts,
            deviceIdForGuard
          )
            .then(async (shiftsResponse) => {
              const filteredShiftsResponse = await Promise.all(
                shiftsResponse.map(async (shift) => await filterShiftEvents(shift))
              );
              cacheShifts(previousCacheKey, filteredShiftsResponse);
            })
            .catch((error) => {
              console.error('Error prefetching previous period:', error);
            });
        }
      } catch (error) {
        console.error('Error fetching shifts:', error);
        toast.error('Failed to load shifts');
      } finally {
        if (!isLoadingGeofences) {
          setIsLoadingShifts(false);
        }
      }
    };

    fetchShifts();
  }, [currentTimePeriod, isLoadingGeofences]);

  useEffect(() => {
    const fetchRankings = async () => {
      if (!devices.length) return;

      try {
        const response = await fetchDashboardLeaderboard(
          effectiveUserId,
          currentTimePeriod.startDate.toISOString(),
          currentTimePeriod.endDate.toISOString(),
          [{ deviceIds: devices }]
        );
        console.log('Leaderboard response:', response);
        setLeaderboardData(response);

        const sortedRankings = response
          .sort((a, b) => b.weeklyTotal - a.weeklyTotal)
          .reduce((acc, curr, index) => {
            acc[curr.deviceId] = index + 1;
            return acc;
          }, {});

        setRankings(sortedRankings);
      } catch (error) {
        console.error('Error fetching leaderboard rankings:', error);
      }
    };

    fetchRankings();
  }, [devices, effectiveUserId, currentTimePeriod]);

  // Filter shifts based on selected personnel
  const filteredShifts = useMemo(() => {
    if (!selectedPersonnel.length) {
      setFlaggedShiftsCount(0);
      return [];
    }

    const filtered = shifts.filter((shift) => selectedPersonnel.includes(shift.deviceId));

    // Calculate flagged shifts count
    const flaggedCount = filtered.reduce((count, shift) => (shift.isFlagged ? count + 1 : count), 0);
    setFlaggedShiftsCount(flaggedCount);

    return filtered;
  }, [shifts, selectedPersonnel]);

  useEffect(() => {
    return () => {
      clearShiftCache();
    };
  }, []);

  // Add effect to clear cache when selectedOrg changes
  useEffect(() => {
    clearShiftCache();
  }, [selectedOrg]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="newdashboard-container">
      <div className="newdashboard-header">
        <div className="newdashboard-header-left">
          <h1>Dashboard</h1>
        </div>
        <div className="newdashboard-header-right">
          <Tooltip title={`Updated at ${formatTooltipTime(lastUpdated)}`} placement="bottom">
            <span>Updated {formatLastUpdated(lastUpdated)}</span>
          </Tooltip>
        </div>
      </div>
      <div className="newdashboard-body">
        <DashboardControls
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          currentTimePeriod={currentTimePeriod}
          setCurrentTimePeriod={setCurrentTimePeriod}
          deviceAssignments={deviceAssignments}
          selectedPersonnel={selectedPersonnel}
          setSelectedPersonnel={setSelectedPersonnel}
        />
        {/* <hr className="newdashboard-divider" /> */}
        {selectedView === 'shift' ? (
          <>
            <Metrics shifts={filteredShifts} currentTimePeriod={currentTimePeriod} isLoading={isLoadingShifts} />
            <ShiftReview
              devices={devices}
              selectedPersonnel={selectedPersonnel}
              setSelectedPersonnel={setSelectedPersonnel}
              userId={effectiveUserId}
              deviceAssignments={deviceAssignments}
              isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
              selectedOrg={selectedOrg}
              isLoadingDevices={isLoadingDevices}
              retentionPeriodDays={retentionPeriodDays}
              shifts={filteredShifts}
              setShifts={setShifts}
              currentTimePeriod={currentTimePeriod}
              isLoadingGeofences={isLoadingGeofences}
              geofences={geofences}
              isLoadingShifts={isLoadingShifts}
              flaggedShiftsCount={flaggedShiftsCount}
              setFlaggedShiftsCount={setFlaggedShiftsCount}
            />
          </>
        ) : (
          <PersonnelStats
            devices={devices}
            userId={effectiveUserId}
            shifts={shifts}
            currentTimePeriod={currentTimePeriod}
            deviceAssignments={deviceAssignments}
            isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
            selectedOrg={selectedOrg}
            leaderboardData={leaderboardData}
            selectedPersonnel={selectedPersonnel}
            isLoading={isLoadingShifts}
          />
        )}
      </div>
    </div>
  );
};

export default NewDashboard;
