import '../../../styles/NewDashboard/PersonnelStats/PersonnelStats.css';
import { useMemo, useState } from 'react';
import PersonnelStatsSkeleton from './PersonnelStatsSkeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { formatDuration } from '../../../utils/timeUtils';

const PersonnelStats = ({
  devices,
  shifts,
  currentTimePeriod,
  deviceAssignments,
  leaderboardData,
  selectedPersonnel,
  isLoading,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: 'avgDailyHours',
    direction: 'desc',
  });
  const deviceStats = useMemo(() => {
    const stats = {};

    // Initialize stats for each device
    devices.forEach((deviceId) => {
      stats[deviceId] = {
        totalHours: 0,
        activationRate: 0,
        highBatteryShiftHours: 0,
        status: 'Offline',
        lastSeen: null,
        daysWithShifts: new Set(),
      };
    });

    // Filter shifts based on time period
    const relevantShifts = shifts.filter((shift) => {
      const shiftDate = new Date(shift.startTime);
      return shiftDate >= currentTimePeriod.startDate && shiftDate <= currentTimePeriod.endDate;
    });

    // Calculate days in period
    const daysInPeriod =
      Math.ceil((currentTimePeriod.endDate - currentTimePeriod.startDate) / (1000 * 60 * 60 * 24)) || 1;

    // Process each shift
    relevantShifts.forEach((shift) => {
      const deviceId = shift.deviceId;
      if (!stats[deviceId]) return;

      if (!shift.duration) {
        shift.duration = Math.floor((Date.now() - new Date(shift.startTime)) / 1000);
      }
      if (!shift.calculatedOnlineTime) {
        shift.calculatedOnlineTime = 0;
      }

      const hours = shift.duration / 3600; // Convert seconds to hours
      const shiftActivationRate = (shift.calculatedOnlineTime / shift.duration) * 100;

      // Track total hours
      stats[deviceId].totalHours += hours;

      // Track activation rate
      stats[deviceId].activationRate += shiftActivationRate * hours;

      // Track shifts that start with high battery
      const firstEvent = shift.events && shift.events[0];
      if (firstEvent && firstEvent.batteryLevel >= 80) {
        stats[deviceId].highBatteryShiftHours += hours;
      }

      // Track unique days with shifts for average calculation
      const shiftDate = new Date(shift.startTime).toDateString();
      stats[deviceId].daysWithShifts.add(shiftDate);

      // Update last seen time and status
      const shiftEndTime = shift.endTime || shift.lastSeen;
      if (shiftEndTime) {
        const lastSeenTime = new Date(shiftEndTime);
        if (!stats[deviceId].lastSeen || lastSeenTime > stats[deviceId].lastSeen) {
          stats[deviceId].lastSeen = lastSeenTime;
          const timeDiff = Date.now() - lastSeenTime.getTime();
          stats[deviceId].status = timeDiff < 30 * 60 * 1000 ? 'Online' : 'Offline';
        }
      }
    });

    // Calculate final averages
    Object.keys(stats).forEach((deviceId) => {
      if (stats[deviceId].totalHours > 0) {
        // Store milliseconds instead of decimal hours
        stats[deviceId].avgDailyHoursMs = (stats[deviceId].totalHours * 3600 * 1000) / daysInPeriod;
        stats[deviceId].avgDailyHours = stats[deviceId].avgDailyHoursMs; // Store raw value for sorting

        // Calculate activation rate
        stats[deviceId].activationRate = (stats[deviceId].activationRate / stats[deviceId].totalHours).toFixed(1);

        // Calculate percentage of shifts starting with high battery
        stats[deviceId].highBatteryPercentage = (
          (stats[deviceId].highBatteryShiftHours / stats[deviceId].totalHours) *
          100
        ).toFixed(1);
      } else {
        stats[deviceId].avgDailyHoursMs = 0;
        stats[deviceId].avgDailyHours = 0;
        stats[deviceId].activationRate = '0.0';
        stats[deviceId].highBatteryPercentage = '0.0';
      }
    });

    return stats;
  }, [shifts, devices, currentTimePeriod]);
  // Add this function at the component level to calculate rankings
  const calculateRankings = useMemo(() => {
    return leaderboardData
      .sort((a, b) => b.weeklyTotal - a.weeklyTotal)
      .reduce((acc, curr, index) => {
        // Only include devices that have a weeklyTotal > 0
        if (curr.weeklyTotal > 0) {
          acc[curr.deviceId] = index + 1;
        }
        return acc;
      }, {});
  }, [leaderboardData]);

  const { sortedDevices, averageDevice } = useMemo(() => {
    const filteredDevices = devices
      .filter((deviceId) => selectedPersonnel.includes(deviceId))
      .filter((deviceId) => deviceStats[deviceId].avgDailyHoursMs > 0);

    const averages = filteredDevices.reduce(
      (acc, deviceId) => {
        acc.avgDailyHours += deviceStats[deviceId].avgDailyHoursMs;
        acc.activationRate += parseFloat(deviceStats[deviceId].activationRate);
        acc.batteryHealth += parseFloat(deviceStats[deviceId].highBatteryPercentage);
        return acc;
      },
      { avgDailyHours: 0, activationRate: 0, batteryHealth: 0 }
    );

    const deviceCount = filteredDevices.length;
    if (deviceCount > 0) {
      averages.avgDailyHours /= deviceCount;
      averages.activationRate = (averages.activationRate / deviceCount).toFixed(1);
      averages.batteryHealth = (averages.batteryHealth / deviceCount).toFixed(1);
    }

    const averageDevice = {
      deviceId: 'average',
      avgDailyHoursMs: averages.avgDailyHours,
      activationRate: averages.activationRate,
      highBatteryPercentage: averages.batteryHealth,
    };

    const sortedDevices = [...filteredDevices, 'average'].sort((a, b) => {
      const deviceA = a === 'average' ? averageDevice : deviceStats[a];
      const deviceB = b === 'average' ? averageDevice : deviceStats[b];

      switch (sortConfig.key) {
        case 'personnel':
          if (a === 'average') return sortConfig.direction === 'asc' ? 1 : -1;
          if (b === 'average') return sortConfig.direction === 'asc' ? -1 : 1;
          const nameA = deviceAssignments[a] || a;
          const nameB = deviceAssignments[b] || b;
          return sortConfig.direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        case 'avgDailyHours':
          return sortConfig.direction === 'asc'
            ? deviceA.avgDailyHoursMs - deviceB.avgDailyHoursMs
            : deviceB.avgDailyHoursMs - deviceA.avgDailyHoursMs;
        case 'activationRate':
          return sortConfig.direction === 'asc'
            ? parseFloat(deviceA.activationRate) - parseFloat(deviceB.activationRate)
            : parseFloat(deviceB.activationRate) - parseFloat(deviceA.activationRate);
        case 'batteryHealth':
          return sortConfig.direction === 'asc'
            ? parseFloat(deviceA.highBatteryPercentage) - parseFloat(deviceB.highBatteryPercentage)
            : parseFloat(deviceB.highBatteryPercentage) - parseFloat(deviceA.highBatteryPercentage);
        case 'score':
          if (a === 'average' || b === 'average') return 0;
          const rankA = calculateRankings[a] || Infinity;
          const rankB = calculateRankings[b] || Infinity;
          return sortConfig.direction === 'asc' ? rankB - rankA : rankA - rankB;
        default:
          return 0;
      }
    });

    return { sortedDevices, averageDevice };
  }, [devices, sortConfig, deviceAssignments, deviceStats, selectedPersonnel, calculateRankings]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // First, let's create a reusable tooltip style object
  const tooltipSx = {
    tooltip: {
      backgroundColor: 'white',
      color: '#666',
      fontSize: '0.8em',
      fontWeight: 300,
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      border: '1px solid #eee',
      padding: '6px 12px',
      maxWidth: 300,
      minWidth: 200,
      lineHeight: 1.3,
    },
  };

  if (isLoading) {
    return <PersonnelStatsSkeleton />;
  }

  return (
    <div className="personnel-stats-container">
      <div className="stats-table">
        <div className="table-header">
          <span
            onClick={() => handleSort('personnel')}
            className={`sortable ${sortConfig.key === 'personnel' ? sortConfig.direction : ''}`}
          >
            <div className="header-main">Name</div>
            <div className="header-subtext">&nbsp;</div>
          </span>
          <span
            onClick={() => handleSort('avgDailyHours')}
            className={`sortable ${sortConfig.key === 'avgDailyHours' ? sortConfig.direction : ''}`}
          >
            <div className="header-main">
              Hours In Shift
              {/* <Tooltip 
                title="Average daily hours spent in shifts during the selected period"
                placement="top"
                sx={tooltipSx}
              >
                <span className="info-tooltip">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </Tooltip> */}
            </div>
            <div className="header-subtext">Daily average</div>
          </span>
          <span
            onClick={() => handleSort('activationRate')}
            className={`sortable ${sortConfig.key === 'activationRate' ? sortConfig.direction : ''}`}
          >
            <div className="header-main">
              Activation Rate
              <Tooltip
                title="Percentage of time device was active during recorded shifts"
                placement="top"
                sx={tooltipSx}
              >
                <span className="info-tooltip">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </Tooltip>
            </div>
            <div className="header-subtext">Percentage across all shifts</div>
          </span>
          <span
            onClick={() => handleSort('batteryHealth')}
            className={`sortable ${sortConfig.key === 'batteryHealth' ? sortConfig.direction : ''}`}
          >
            <div className="header-main">
              Charged Shifts
              <Tooltip
                title="Percentage of time personnel started their shift with battery level above 80%"
                placement="top"
                sx={tooltipSx}
              >
                <span className="info-tooltip">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </Tooltip>
            </div>
            <div className="header-subtext">Percentage across all shifts</div>
          </span>
          <span
            onClick={() => handleSort('score')}
            className={`sortable ${sortConfig.key === 'score' ? sortConfig.direction : ''}`}
          >
            <div className="header-main">
              Ranking
              <Tooltip
                title="Ranking based on points earned for activating and charging"
                placement="top"
                sx={tooltipSx}
              >
                <span className="info-tooltip">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </Tooltip>
            </div>
            <div className="header-subtext">Leaderboard position</div>
          </span>
        </div>
        {sortedDevices.map((deviceId) => {
          const isAverage = deviceId === 'average';
          const device = isAverage ? averageDevice : deviceStats[deviceId];

          const hasHours = device && device.avgDailyHoursMs > 0;

          return (
            <div key={deviceId} className={`table-row ${isAverage ? 'average-row' : ''}`}>
              <span>{isAverage ? 'Average' : deviceAssignments[deviceId] || deviceId}</span>
              <span>{hasHours ? formatDuration(device.avgDailyHoursMs) : '0h 0m'}</span>
              <span>{hasHours ? `${device.activationRate}%` : '-'}</span>
              <span>{hasHours ? `${device.highBatteryPercentage}%` : '-'}</span>
              <span>{isAverage ? '-' : calculateRankings[deviceId] ? `#${calculateRankings[deviceId]}` : '-'}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PersonnelStats;
