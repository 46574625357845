import React, { useState, useEffect, useMemo } from 'react';
import MetricsControls from './MetricsControls/MetricsControls';
import MetricsCard from './MetricsCard/MetricsCard';
import MetricCardSkeleton from './MetricsCard/MetricCardSkeleton';
import '../../../styles/NewDashboard/Metrics/Metrics.css';
import { formatDuration } from '../../../utils/timeUtils';

const Metrics = ({ shifts, currentTimePeriod, isLoading }) => {
  const [displayMetrics, setDisplayMetrics] = useState({
    activationRate: null,
    escalations: null,
    averageShiftDuration: null,
    uniqueSitesCount: null,
    recordingHours: null,
  });

  const getRelevantShifts = (shifts, currentTimePeriod) => {
    if (!shifts || !shifts.length) return [];

    const startDate = currentTimePeriod.startDate;
    const endDate = currentTimePeriod.endDate;

    return shifts.filter((shift) => {
      const shiftDate = new Date(shift.startTime);
      return shiftDate >= startDate && shiftDate <= endDate;
    });
  };

  const getTimePeriodDescription = (timePeriod) => {
    switch (timePeriod.name) {
      case 'today':
        return "today's";
      case 'thisWeek':
        return "this week's";
      case 'pastWeek':
        return "past week's";
      case 'lastWeek':
        return "last week's";
      case 'lastMonth':
        return "last month's";
      case 'older':
        return 'older';
      default:
        return '';
    }
  };

  const calculateActivationRate = (relevantShifts) => {
    if (!relevantShifts) return null;

    if (relevantShifts.length === 0) return null;

    let totalActivationRate = 0;
    let validShifts = 0;

    relevantShifts.forEach((shift) => {
      if (shift.duration && shift.calculatedOnlineTime) {
        const activationRate = (shift.calculatedOnlineTime / shift.duration) * 100;
        totalActivationRate += activationRate;
        validShifts++;
      }
    });

    return validShifts > 0 ? (totalActivationRate / validShifts).toFixed(1) : null;
  };

  const calculateTodayEscalations = (relevantShifts) => {
    if (!relevantShifts) return null;

    if (relevantShifts.length === 0) return null;

    let totalEscalations = 0;

    relevantShifts.forEach((shift) => {
      if (shift.events) {
        const escalations = shift.events.filter((event) => event.type === 'escalation');
        totalEscalations += escalations.length;
      }
    });

    return totalEscalations;
  };

  const calculateAverageShiftDuration = (relevantShifts) => {
    if (!relevantShifts) return null;

    if (relevantShifts.length === 0) return null;

    let totalDuration = 0;
    let validShifts = 0;

    relevantShifts.forEach((shift) => {
      if (shift.duration) {
        totalDuration += shift.duration;
        validShifts++;
      }
    });

    if (validShifts === 0) return null;

    // Convert seconds to hours and round to 1 decimal place
    const averageHours = (totalDuration / validShifts / 3600).toFixed(1);
    return averageHours;
  };

  const calculateUniqueSites = (relevantShifts) => {
    if (!relevantShifts) return null;

    if (relevantShifts.length === 0) return null;

    const uniqueSites = new Set();

    relevantShifts.forEach((shift) => {
      if (shift.events) {
        shift.events.forEach((event) => {
          if (event.type === 'locationEnter' && event.geofenceId) {
            uniqueSites.add(event.geofenceId);
          }
        });
      }
    });

    return uniqueSites.size;
  };

  const calculateTotalRecordingHours = (relevantShifts) => {
    if (!relevantShifts) return null;
    if (relevantShifts.length === 0) return null;

    let totalRecordingDuration = 0;

    relevantShifts.forEach((shift) => {
      if (shift.totalRecordingDuration) {
        totalRecordingDuration += shift.totalRecordingDuration;
      }
    });

    return totalRecordingDuration;
  };

  const currentMetrics = useMemo(() => {
    const relevantShifts = getRelevantShifts(shifts, currentTimePeriod);
    return {
      activationRate: calculateActivationRate(relevantShifts),
      escalations: calculateTodayEscalations(relevantShifts),
      averageShiftDuration: calculateAverageShiftDuration(relevantShifts),
      uniqueSitesCount: calculateUniqueSites(relevantShifts),
      recordingHours: calculateTotalRecordingHours(relevantShifts),
    };
  }, [shifts, currentTimePeriod]);

  // Update metrics when data is available
  useEffect(() => {
    if (!isLoading && currentMetrics.activationRate !== null) {
      const timeoutId = setTimeout(() => {
        setDisplayMetrics(currentMetrics);
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [currentMetrics, isLoading]);

  if (isLoading) {
    return (
      <div className="metrics-container">
        <div className="metrics-cards-container">
          <MetricCardSkeleton />
          <MetricCardSkeleton />
          <MetricCardSkeleton />
          {/* <MetricCardSkeleton /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="metrics-container">
      <div className="metrics-cards-container">
        <MetricsCard
          title="Activation Rate"
          value={displayMetrics.activationRate ? `${displayMetrics.activationRate}%` : '-'}
          description="Percentage of time active during recorded shifts"
          trend={null}
        />
        <MetricsCard
          title="Alerts"
          value={displayMetrics.escalations !== null ? displayMetrics.escalations : '-'}
          description="Number of incidents detected"
          trend={null}
        />
        {/* <MetricsCard 
          title="Average Shift Duration"
          value={displayMetrics.averageShiftDuration ? `${displayMetrics.averageShiftDuration}h` : 'N/A'}
          description={`Average shift duration from ${getTimePeriodDescription(currentTimePeriod)} shifts`}
          trend={null}
        />
        <MetricsCard 
          title="Sites Visited"
          value={displayMetrics.uniqueSitesCount !== null ? displayMetrics.uniqueSitesCount : 'N/A'}
          description={`Number of sites visited from ${getTimePeriodDescription(currentTimePeriod)} shifts`}
          trend={null}
        /> */}
        <MetricsCard
          title="Recording Time"
          value={displayMetrics.recordingHours ? formatDuration(displayMetrics.recordingHours) : '-'}
          description="Total across all shifts"
          trend={null}
        />
      </div>
    </div>
  );
};

export default Metrics;
