import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Clear invalid token
      localStorage.removeItem('access_token');
      localStorage.removeItem('auth_version');
      // Force App.js to get new token
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const rateLimiter = {
  getLastReload() {
    const storedTime = localStorage.getItem('last_reload_time');
    return storedTime ? parseInt(storedTime) : this.lastReload || 0;
  },
  lastReload: 0, // Initialize lastReload to 0 for backward compatibility
  minInterval: 5000, // 5 seconds

  canReload() {
    const now = Date.now();
    const lastReload = this.getLastReload();
    if (now - lastReload < this.minInterval) {
      return false;
    }
    this.setLastReload(now);
    return true;
  },
};

export const checkTokenValidity = () => {
  const token = localStorage.getItem('access_token');
  const authVersion = localStorage.getItem('auth_version');
  const REQUIRED_AUTH_VERSION = '1.0';
  const tokenFetchInProgress = localStorage.getItem('token_fetch_in_progress');
  // console.log("7898 token", token);
  // console.log('Token Validation Check:', {
  //   hasToken: !!token,
  //   tokenValue: token?.substring(0, 10) + '...', // Show first 10 chars of token
  //   authVersion,
  //   requiredVersion: REQUIRED_AUTH_VERSION,
  //   tokenFetchInProgress: !!tokenFetchInProgress
  // });

  // If token fetch is in progress, wait
  if (tokenFetchInProgress) {
    console.log('Token fetch in progress branch');
    const startTime = parseInt(tokenFetchInProgress);
    if (Date.now() - startTime < 10000) {
      console.log('Token fetch in progress, waiting...');
      return false;
    }
    localStorage.removeItem('token_fetch_in_progress');
  }

  // No token or invalid version
  if (!token || authVersion !== REQUIRED_AUTH_VERSION) {
    if (rateLimiter.canReload()) {
      // Instead of immediate reload, redirect to home
      window.location.href = '/';
      return false;
    }
    return false;
  }

  console.log('Token validation successful');
  return true;
};

export const fetchAlertFromApi = async (alertId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/${alertId}`;

    console.log('URL:', url);
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Response:', response.data);

    if (!response.data.IsCustomerVisible) {
      return null; // Return null if the alert shouldn't be shown to the customer
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching alert from API:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch alert: ${error.message}`);
  }
};

export const fetchRecentEscalationAlerts = async (userId, deviceIds, lastTimestamp, pageSize = 20) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/recent`;

    const response = await axios.post(
      url,
      {
        userId,
        deviceIds,
        lastTimestamp,
        pageSize,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Recent escalation alerts response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent escalation alerts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch recent escalation alerts: ${error.message}`);
  }
};

export const getAlertLabelingMetrics = async (userId, deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/labeling-metrics`;

    const response = await axios.post(
      url,
      { userId, deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error getting alert labeling metrics:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to get alert labeling metrics: ${error.message}`);
  }
};

export const filterShiftEvents = async (shift) => {
  try {
    const shiftId = shift.id;
    console.log('Shift:', shift);

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts/filter`;

    const response = await axios.post(
      url,
      { shiftId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error filtering shift events:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to filter shift events: ${error.message}`);
  }
};

export const fetchShiftsForDevices = async (userId, deviceIds, page = 1, pageSize = 50) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts`;

    const response = await axios.post(
      url,
      {
        userId,
        deviceIds,
        page,
        pageSize,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching shifts for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch shifts for devices: ${error.message}`);
  }
};

export const fetchAllFlaggedShifts = async (deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/flagged-shifts`;

    const response = await axios.post(
      url,
      { deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching flagged shifts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch flagged shifts: ${error.message}`);
  }
};

export const sendReport = async (orgUserId, emailAddress, reportType, frequency) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/send-report`;
    console.log('send report url: ', url);

    const response = await axios.post(
      url,
      {
        org_user_id: orgUserId,
        email_address: emailAddress,
        report_type: reportType,
        frequency: frequency,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('send report response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error send report:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to send report: ${error.message}`);
  }
};

export const fetchDevicesFromUserId = async (userId) => {
  try {
    // Store the result of checkTokenValidity
    const isTokenValid = checkTokenValidity();
    if (!isTokenValid) {
      throw new Error('Token validation failed');
    }

    let token = localStorage.getItem('access_token');
    console.log('token:', token);
    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/user`;
    console.log('url:', url);

    const response = await axios.post(
      url,
      { userId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.data) {
      throw new Error('No data received from API');
    }

    console.log('Devices response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching devices for user:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw error; // Throw the error instead of wrapping it
  }
};

export const fetchDeviceData = async (deviceId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/device/${deviceId}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Device data response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching device data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch device data: ${error.message}`);
  }
};

export const updateDeviceData = async (deviceId, updateData) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/${deviceId}`;

    const response = await axios.post(url, updateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Update device data response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating device data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update device data: ${error.message}`);
  }
};

export const fetchAssignedToFromDeviceIds = async (deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/assigned-to`;

    const response = await axios.post(
      url,
      { deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('AssignedTo response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignedTo from deviceIds:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch assignedTo from deviceIds: ${error.message}`);
  }
};

export const fetchVideosForDevices = async (userId, devices, retentionPeriodDays, selectedOrg) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/videos`;

    // Extract only the device IDs from the devices array
    const deviceIds = devices.map((device) => device.deviceId);

    const response = await axios.post(
      url,
      { userId, deviceIds, retentionPeriodDays, selectedOrg },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Videos response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching videos for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch videos for devices: ${error.message}`);
  }
};
export const fetchVideoByFileName = async (fileName) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/videos/${fileName}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Video response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching video by fileName:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch video by fileName: ${error.message}`);
  }
};

export const updateShiftFlagStatus = async (id, orgUserId, isFlagged) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts/flag`;

    const response = await axios.post(
      url,
      { id, orgUserId, isFlagged },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Shift response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating shift flag status:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update shift flag status: ${error.message}`);
  }
};

export const fetchLatestLocationEvent = async (deviceId, endTime) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/latest/${deviceId}/${endTime}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Latest location event response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest location event:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch latest location event: ${error.message}`);
  }
};

export const fetchOrganizationsFromCosmos = async () => {
  try {
    if (!checkTokenValidity()) return;

    // For production, use the token from localStorage
    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Organizations response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations from Cosmos:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch organizations from Cosmos: ${error.message}`);
  }
};

export const fetchBatteryMetricsForDevices = async (devices) => {
  try {
    if (!checkTokenValidity()) return;

    // For production, use the token from localStorage
    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/battery-metrics`;

    const response = await axios.post(
      url,
      { devices },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Battery metrics response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching battery metrics for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch battery metrics for devices: ${error.message}`);
  }
};

export const updateAlertLabels = async (alertId, deviceId, newLabels) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/update-labels`;

    const response = await axios.post(
      url,
      { alertId, deviceId, newLabels },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating alert labels:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update alert labels: ${error.message}`);
  }
};

export const fetchGeofencesForOrganization = async (organizationId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/${organizationId}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Geofences response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching geofences for organization:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch geofences for organization: ${error.message}`);
  }
};

export const addGeofence = async (name, organization, center, radius, nickname) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/add`;

    const response = await axios.post(
      url,
      { name, organization, center, radius, nickname },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to add geofence: ${error.message}`);
  }
};

export const updateGeofence = async (id, organization, newName, center, radius) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/update`;

    const response = await axios.post(
      url,
      { id, organization, newName, center, radius },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update geofence: ${error.message}`);
  }
};

export const deleteGeofence = async (id, organization) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/geofences/delete`;

    const response = await axios.post(
      url,
      { id, organization },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to delete geofence: ${error.message}`);
  }
};

export const fetchLocationAtTimestamp = async (deviceId, timestamp) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/time/${deviceId}/${timestamp}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Location at timestamp response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching location at timestamp:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch location at timestamp: ${error.message}`);
  }
};

export const fetchLocationsForShift = async (deviceId, startTime, endTime) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/shift/${deviceId}/${startTime}/${endTime}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Locations for shift response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching locations for shift:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch locations for shift: ${error.message}`);
  }
};

export const fetchBatteryRecordingStatus = async (deviceId, startTime, endTime, escalationTimeSpans) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/battery-recording-status`;

    const response = await axios.post(
      url,
      { deviceId, startTime, endTime, escalationTimeSpans },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Battery recording status response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching battery recording status:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch battery recording status: ${error.message}`);
  }
};

// export const fetchUserRoles = async (accessToken) => {
//   try {
//     if (!accessToken) {
//       throw new Error('No access token provided');
//     }

//     const url = process.env.REACT_APP_BEAVER_API_URL
//       ? `${process.env.REACT_APP_BEAVER_API_URL}/api/user-roles`
//       : 'https://beaver.plix.ai/api/user-roles';

//     const response = await axios.get(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         'Content-Type': 'application/json',
//       },
//     });

//     if (!response.status === 200) {
//       throw new Error('Failed to fetch user roles');
//     }

//     const userData = response.data;
//     if (userData && userData.length > 0) {
//       const roleName = userData[0].name;
//       console.log('User role:', roleName);
//       return roleName;
//     } else {
//       console.log('No roles found for the user');
//       return null;
//     }
//   } catch (error) {
//     console.error('Error fetching user roles:', error);
//     throw error;
//   }
// };

// Device Configs API (ported from deviceConfig.js)

export const fetchDeviceConfigs = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/config/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching device configs:', error);
    throw error;
  }
};

export const pingDevices = async (userId, lastChargeAndRecordOnly = false, userRole = null) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/ping/${userId}`;
    const response = await axios.get(url, {
      params: {
        lastChargeAndRecordOnly,
        userRole,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error pinging devices:', error);
    throw error;
  }
};

// Create a single axios instance
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: 30000,
// });

// Shared video endpoint should use its own instance since it doesn't need auth
const publicApi = axios.create({
  baseURL: process.env.REACT_APP_BEAVER_API_URL,
  // baseURL: 'http://localhost:4000',
  timeout: 30000,
});
export const createSharedVideo = async (
  videoFileName,
  isDownloadable,
  expirationDate,
  password = null,
  initialTime = 0,
  userId
) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shared-video/create`;
    // const url = `http://localhost:4000/api/shared-video/create`;

    const requestBody = { videoFileName, isDownloadable, expirationDate, password, initialTime, userId };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    console.log('Create Shared Video Request:');
    console.log('URL:', url);
    console.log('Method: POST');
    console.log('Headers:', JSON.stringify(headers, null, 2));
    console.log('Body:', JSON.stringify(requestBody, null, 2));

    const response = await axios.post(url, requestBody, { headers });

    console.log('Response:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    console.error('Error creating shared video:', error);
    throw error;
  }
};

export const fetchSharedVideo = async (sharedVideoId, password = '') => {
  try {
    const response = await publicApi.get(`/api/shared-video/${sharedVideoId}`, {
      headers: password ? { 'X-Video-Password': password } : {},
    });
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};

export const logUserInteraction = async (action, metadata, userId, userName) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const response = await publicApi.post(
      '/api/videos/log-user-interaction',
      { action, metadata, userId, userName },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error logging user interaction:', error);
    throw error;
  }
};

export const logDownloadInteraction = async (action, metadata, userId, userName, password) => {
  try {
    const response = await publicApi.post(
      `/api/shared-video/log-download-interaction`,
      { action, metadata, userId, userName },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Video-Password': password,
        },
      }
    );
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};
export const fetchUserMapping = async (getAccessTokenSilently) => {
  try {
    // Get fresh token directly instead of relying on localStorage
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email',
      },
    });

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/debug/user-mapping`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    // Validate response structure
    if (!response?.data?.mapped?.userId || !response?.data?.mapped?.mode) {
      console.error('Invalid mapping response:', response.data);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (status === 401) {
        // throw new Error('Authentication failed. Please log in again.');
        console.error('Authentication failed. Please log in again.');
      } else if (status === 403) {
        // throw new Error('You do not have permission to access this resource.');
        console.error('You do not have permission to access this resource.');
      }
    }
    console.error('Error fetching user mapping:', error);
    throw error;
  }
};

export const getUserRoleFromToken = (user) => {
  try {
    // Access the roles claim from the user object
    const roles = user['https://beaver.plix.ai/roles'] || [];
    const isGuard = roles.includes('guard');
    return isGuard ? 'guard' : 'admin'; // or whatever your role logic is
  } catch (error) {
    console.error('Error getting user role from token:', error);
    return null;
  }
};

// Leaderboard

export const fetchWeeklyLeaderboard = async (organizationId, weekStart, weekEnd) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/leaderboard/weekly`;

    const response = await axios.get(url, {
      params: { organizationId, weekStart, weekEnd },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Leaderboard response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching weekly leaderboard:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch weekly leaderboard: ${error.message}`);
  }
};

export const fetchDashboardLeaderboard = async (organizationId, weekStart, weekEnd, deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    const token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/leaderboard/dashboard`;
    const response = await axios.post(
      url,
      { organizationId, weekStart, weekEnd, deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard leaderboard:', error);
    throw error;
  }
};

// SM Config API

export const fetchConfig = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
};

export const updateConfig = async (userId, config) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/${userId}`;
    const response = await axios.post(url, config, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating config:', error);
    throw error;
  }
};

export const fetchRetentionPeriod = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/retention-period/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.retentionPeriodDays;
  } catch (error) {
    console.error('Error fetching retention period:', error);
    throw error;
  }
};

export const fetchShiftsByDateRange = async (startDate, endDate, userId = null, deviceId = null) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shift/date`;
    const response = await axios.post(
      url,
      { startDate, endDate, userId, deviceId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching shifts by date range:', error);
    throw error;
  }
};

export const updateDeviceTag = async (deviceId, deviceTag) => {
  try {
    if (!checkTokenValidity()) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      throw new Error('No access token found');
    }

    const url = 'https://utils.plix.ai/v1/addtag';
    const response = await axios.post(
      url,
      { deviceId, deviceTag },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(`Error updating device tag for device ${deviceId}:`, error);
    throw error;
  }
};

export const triggerConfigUpdate = async (deviceId) => {
  try {
    if (!checkTokenValidity()) return;

    const url = 'https://utils.plix.ai/v1/updateconfig';
    const response = await axios.post(
      url,
      {
        deviceId: deviceId,
        updateType: 'fetch config',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(`Configuration update triggered for device ${deviceId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Failed to trigger configuration update for device ${deviceId}:`, error);
    throw error;
  }
};
